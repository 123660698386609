import { SET_CURRENT_USER, SET_CURRENT_USER_PROFILE, SET_CURRENT_USER_AND_PROFILE } from './constants'
import { SIGN_OUT } from '../../auth/store/constants'
import { isEqual } from '../../../utils/object'

const initialState = {
  user: null,
  profile: null,
}

const currentUserReducer = (state = { ...initialState }, { type, payload }) => {
  switch (type) {
    case SET_CURRENT_USER:
      if (isEqual(state.user, payload)) {
        return state
      }
      return { ...state, user: payload }
    case SET_CURRENT_USER_PROFILE:
      if (isEqual(state.profile, payload)) {
        return state
      }
      return { ...state, profile: payload }
    case SET_CURRENT_USER_AND_PROFILE:
      return {
        ...state, ...payload,
      }
    case SIGN_OUT:
      return { ...initialState }
    default:
      return state
  }
}

export default currentUserReducer
